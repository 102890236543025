import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'

import * as s from '../../pages/news.module.scss'

const IvanGordienkoJoinedPage: React.FC = () => {
  return (
    <>
      <SEO
        title="ГОРДИЕНКО ИВАН ПРИСОЕДИНИЛСЯ К КОМАНДЕ SIGNAL"
        description="Гордиенко Иван присоединился к команде SIGNAL"
      />
      <NewsSection
        title="Гордиенко Иван присоединился к команде SIGNAL"
        dateData={{ date: '07.08.2023' }}
        rayContent
      >
        <div className={s.authorWrapper}>
          <StaticImage
            width={93}
            src="../../assets/images/news/ivan-gordienko-joined/popov.png"
            alt="Alexandr Popov"
            title="Alexandr Popov"
            placeholder="blurred"
          />
          <div className={s.author}>
            Александр Попов
            <p>Технический директор, SIGNAL</p>
          </div>
        </div>
        <p className={s.text}>
          Я рад сообщить, что к команде SIGNAL присоединился Иван Гордиенко, как
          менеджер по продуктовому маркетингу.
        </p>
        <p className={s.text}>
          В этой роли Иван будет отвечать за развитие нашего продукта и его
          продвижение в России, а также в перспективе на мировом рынке.
        </p>
        <p className={s.text}>
          Иван экс менеджер по продукту CSD - официального дистрибутора Autodesk
          в России и экс PR-директор проекта PROТИМ. Признанный эксперт по
          облачным сервисам Autodesk BIM 360 и Construction Cloud, провел
          десятки вебинаров по этим продуктам и адаптировал их под российских
          пользователей, выводил SIGNAL в составе дистрибутора на рынок,
          участник ключевых конференций в области цифровизации и оптимизации
          жизненного цикла строительных объектов, автор статей о преимуществах
          использования облачных сервисов в строительстве и бизнес-процессах
          компаний.
        </p>
        <p className={s.text}>
          Его творческий подход, амбициозность и гибкость мышления помогут нам
          выделяться среди конкурентов и стать лидерами цифровизации отрасли.
        </p>
        <div className="flex">
          <StaticImage
            width={449}
            className={s.imageBlock}
            src="../../assets/images/news/ivan-gordienko-joined/gordienko.png"
            alt="ГОРДИЕНКО ИВАН ПРИСОЕДИНИЛСЯ К КОМАНДЕ SIGNAL"
            title="Гордиенко Иван присоединился к команде SIGNAL"
            placeholder="blurred"
          />
        </div>
      </NewsSection>
    </>
  )
}

export default IvanGordienkoJoinedPage
